const scrollToTop = (pathname) => {
  const routes = [/\/blog\/.*/, /\/*-policy/]
  const shouldScroll =
    routes.filter(route => {
      const regex = new RegExp(route)
      return regex.test(pathname)
    }).length > 0
  if (shouldScroll) {
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 100)
  }
}

const scrollToFragment = () => {
  const hash = window.location.hash
  if (hash) {
    setTimeout(function () {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView()
      }
    }, 100)
  }
}

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location
  scrollToTop(pathname)
  scrollToFragment()
  return true
}
